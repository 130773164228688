import "./main2.js";

// ajax form
$('.contactform').on('submit', function (e) {
    e.preventDefault();
    var url = $(this).attr("action");

    $("#contactform").ajaxSubmit({
        type: "POST",
        url: url,
        dataType: 'JSON',
        data: $(this).serialize(),
        beforeSend: function () {
            $(".ajax_load").fadeIn(200).css("display", "flex");
        },
        success: function (res) {
            $(".ajax_load").fadeOut(200);

            if (res.success) {
                $('.contactform input[type="text"], .contactform input[type="file"]').val('');
                $('.contactform textarea').val('');
            }
            swal(res.title, res.message, res.icon);
        }
    });
});

$('.j_form_buttom').on('click', function (e){
    e.preventDefault();

    var action = $(this).data('action');

    $(".form_buttoms_item").removeClass("active");
    $(this).addClass("active");

    if(action === 'trabalhe-conosco'){
        $('#contactform').find('.curriculo').fadeIn(400);
        $('#contactform').find('input[name=action]').val('trabalhe-conosco');
        $('.form_title h2').html('Trabalhe<strong>Conosco</strong>');
    }else{
        $('.form_title h2').html('Entre<strong>em Contato</strong>');
        $('#contactform').find('.curriculo').fadeOut(400);
        $('#contactform').find('.curriculo input').val('');
        $('#contactform').find('input[name=action]').val('contato');
    }

});